import React, { useCallback, useState, useEffect, useRef }  from 'react';
import Logo from '../images/aea3cd7b67d8004487e2416c81383cf9.svg';

import LinkExternal from '../images/Link_external.svg';
import LogoIn from '../images/Log_in.svg';
import PDF from '../documents/MTX_B2B_WhitePaper.pdf';

const style1 = {
  height: '16096.9px',
  display: 'block',
  top: '70px',
}
const style2 = {
  transform: 'translateY(0px)',
  transition: 'transform 400ms ease 0s'
}
const style3 = {
  transform: 'translate3d(0px, 0px, 460px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)',
};

const Header: React.FC = () => {
  const [supportActive, setsupportActive] = useState(false);
  const [serviceActive, setServiceActive] = useState(false);
  const [mnoActive, setMNOactive] = useState(false);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const bar = useRef(null);

  useEffect(() => {
    setTimeout(() => {
      if (bar.current) {
        // @ts-ignore
        bar.current.className += ' active';
      }
    }, 3000);
  }, []);

  const openChat = useCallback(() => {
    $crisp.push(["do", "chat:show"]);
    $crisp.push(['do', 'chat:open']);
  }, []);

  return (<div className='page-content body'>
      <div data-animation="default" data-collapse="medium" data-duration="400" data-easing="ease" data-easing2="ease" role="banner" className={`nav-component w-nav`}>
      {/* @ts-ignore */}
      <div linkedin_conversion="window.lintrk(&#x27;track&#x27;, { conversion_id: 6820562 });" className="upper-nav_wrapper">
        <div className="w-layout-grid upper-nav_columns">
          <div className="whitepaper-wrapper">
            <div className="announcement-text">Get MTX Connect Business white–paper in PDF.<strong><br /></strong></div>
            <a href={PDF} className="small-tag w-inline-block">
              <div>Download</div>
            </a>
          </div>
          <div id="w-node-_28d276b3-2ac2-2461-97cc-c72003abe79a-03abe78e" className="upper-nav_right-flex hidden-on-tablet">
            <a href="https://www.mtxc.eu/en/coverage.html" className="main-nav_nav-link small white w-inline-block">
              <div className="nav-link_link-text">Coverage</div>
            </a>
            <a href="https://faq.mtxc.eu/" className="main-nav_nav-link small white w-inline-block">
              <div className="nav-link_link-text">FAQ</div>
            </a>
            <div data-delay="0" data-hover="true" className="nav-menu_dropdown w-dropdown">
              <div className="main-nav_nav-link flex small white w-dropdown-toggle" onClick={() => setsupportActive(!supportActive)}>
                <div className="nav-link_link-text">Support 24/7</div><img src="https://uploads-ssl.webflow.com/5ffd76b1a1519877c62735a1/5ffd83f31950665b6b70bb2b_arrow.png" width="7" alt="" className="arrow white" />
              </div>
              <nav className={`main-nav_dropdown_list w-dropdown-list ${supportActive ? 'w--open' : ''}`}>
                <a onClick={openChat} className="main-nav_dropdown-link w-dropdown-link">Chat</a>
                <a href="mailto:support@mtxc.eu?subject=MTXC%20B2B%20Support%20Request" className="main-nav_dropdown-link w-dropdown-link">Email</a>
                <a href="tel:+35220210072" className="main-nav_dropdown-link w-dropdown-link">+352 2021 0072</a>
              </nav>
            </div>
            <a href="https://b2b.mtxc.eu/login" className='main-nav_nav-link flex small white w-inline-block'>
              <img src={LogoIn} loading="lazy" alt="" className="navigation-link_icon-large white" />
              <div className="nav-link_link-text">Log in</div>
            </a>
          </div>
        </div>
      </div>
      <div className="main-nav_wrapper">
        <div className="main-nav_left-flex">
          <a href="https://b2b.mtxc.eu/" className='main-nav_brand w-nav-brand'>
            <img src={Logo} loading="lazy" alt="MTXC Logo" className="custom-nav-logo" />
          </a>
          <nav role="navigation" className="main-nav_nav-menu w-nav-menu">
            <div data-hover="true" data-delay="0" className="nav-menu_dropdown w-dropdown">
              <div className="main-nav_nav-link flex w-dropdown-toggle" onClick={() => setServiceActive(!serviceActive)}>
                <div className="nav-link_link-text">SMB &amp; Enterprise</div>
                <img src="https://uploads-ssl.webflow.com/5ffd76b1a1519877c62735a1/5ffd83f31950665b6b70bb2b_arrow.png" width="7" alt="" className="arrow" />
              </div>
              <nav className={`main-nav_dropdown_list w-dropdown-list ${serviceActive ? 'w--open' : ''}`}>
                <a href="https://b2b.mtxc.eu/roaming-internet" className='main-nav_dropdown-link w-dropdown-link'>Roaming Internet</a>
                <a href="https://b2b.mtxc.eu/sim-and-esim-cards" className='main-nav_dropdown-link w-dropdown-link'>SIM and eSIM Cards</a>
                <a href="https://b2b.mtxc.eu/always-on" className='main-nav_dropdown-link w-dropdown-link'>Always On</a>
                <a href="https://b2b.mtxc.eu/coming-soon" className='main-nav_dropdown-link w-dropdown-link'>Roaming SMS</a>
                <a href="https://b2b.mtxc.eu/coming-soon" className='main-nav_dropdown-link w-dropdown-link'>Roaming Voice</a>
                <a href="https://b2b.mtxc.eu/coming-soon" className='main-nav_dropdown-link w-dropdown-link'>Mobile Numbers</a>
              </nav>
            </div>
            <a href="https://plte.mtxc.eu/" className="main-nav_nav-link w-inline-block">
              <div className="nav-link_link-text">Private LTE</div>
            </a>
            <div data-hover="true" data-delay="0" className="nav-menu_dropdown w-dropdown">
              <div className="main-nav_nav-link flex w-dropdown-toggle" onClick={() => setMNOactive(!mnoActive)}>
                <div className="nav-link_link-text">MNO &amp; MVNO</div><img src="https://uploads-ssl.webflow.com/5ffd76b1a1519877c62735a1/5ffd83f31950665b6b70bb2b_arrow.png" width="7" alt="" className="arrow" />
              </div>
              <nav className={`main-nav_dropdown_list w-dropdown-list ${mnoActive ? 'w--open' : ''}`}>
                <a href="https://b2b.mtxc.eu/carrier-solutions" className='main-nav_dropdown-link w-dropdown-link'>Solutions home</a>
                <a href="https://b2b.mtxc.eu/agreement-database-2-0" className='main-nav_dropdown-link w-dropdown-link'>Agreement Database 2.0</a>
                <a href="https://b2b.mtxc.eu/steering-control" className='main-nav_dropdown-link w-dropdown-link'>Steering Control</a>
                <a href="https://b2b.mtxc.eu/order-tables" className='main-nav_dropdown-link w-dropdown-link'>Order Tables</a>
              </nav>
            </div>
            <a href="https://www.mtxc.eu/" className="main-nav_nav-link flex w-inline-block with-anim">
              <div className="nav-link_link-text">For individuals</div><img src={LinkExternal} width="7" alt="External page icon" className="navigation-link_icon medium" />
            </a>
            <div className="visible-on-tablet-mobile">
              <a href="https://www.mtxc.eu/en/coverage.html" className="main-nav_nav-link w-inline-block">
                <div className="nav-link_link-text">Coverage</div>
              </a>
              <a href="https://faq.mtxc.eu/" className="main-nav_nav-link w-inline-block">
                <div className="nav-link_link-text">FAQ</div>
              </a>
              <div data-hover="true" data-delay="0" className="nav-menu_dropdown w-dropdown">
                <div className="main-nav_nav-link flex w-dropdown-toggle">
                  <div className="nav-link_link-text">MNO &amp; MVNO</div><img src="https://uploads-ssl.webflow.com/5ffd76b1a1519877c62735a1/5ffd83f31950665b6b70bb2b_arrow.png" width="7" alt="" className="arrow" />
                </div>
                <nav className="main-nav_dropdown_list w-dropdown-list">
                  <a href="https://b2b.mtxc.eu/carrier-solutions" className="main-nav_dropdown-link w-dropdown-link">Solutions home<strong><br /></strong></a>
                  <a href="https://b2b.mtxc.eu/agreement-database-2-0" className="main-nav_dropdown-link w-dropdown-link">Agreement Database 2.0<strong><br /></strong></a>
                  <a href="https://b2b.mtxc.eu/steering-control" className="main-nav_dropdown-link w-dropdown-link">Steering Control<strong><br /></strong></a>
                  <a href="https://b2b.mtxc.eu/order-tables" aria-current="page" className="main-nav_dropdown-link w-dropdown-link w--current">Order Tables<strong><br /></strong></a>
                </nav>
              </div>
              <div className="line-divider gray"></div>
              <div className="margin-20px">
                <div className="pading-horizontal _5">
                  <div className="align-left">
                    <a href="https://b2b.mtxc.eu/sign-up" className='button _200px full-on-mobile w-button'>Sign up</a>
                    <div className="margin-20px">
                      <a href="https://b2b.mtxc.eu/login" className='button light-gray _200px full-on-mobile w-button'>Log in</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </nav>
        </div>
        <div className="main-nav_right-flex">
          <div className="hidden-on-tablet-mobile">
            <a href="https://b2b.mtxc.eu/sign-up" className='button small w-button'>Sign up</a>
          </div>
          <div
           className={`menu-button w-nav-button ${mobileMenuOpen ? 'active' : ''}`}
           onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
          >
            <div className="hamburger-line first"></div>
            <div className="hamburger-line second"></div>
          </div>
        </div>
      </div>
      {mobileMenuOpen && <div className="w-nav-overlay" data-wf-ignore="" id="w-nav-overlay-0" style={style1}><nav role="navigation" className="main-nav_nav-menu w-nav-menu" data-nav-menu-open="" style={style2}>
        <div data-hover="true" data-delay="0" className="nav-menu_dropdown w-dropdown w--nav-dropdown-open">
          <div className="main-nav_nav-link flex w-dropdown-toggle w--nav-dropdown-toggle-open" id="w-dropdown-toggle-1" aria-controls="w-dropdown-list-1" aria-haspopup="menu" aria-expanded="false" role="button" onClick={() => setServiceActive(!serviceActive)}>
            <div className="nav-link_link-text">SMB &amp; Enterprise</div><img src="https://uploads-ssl.webflow.com/5ffd76b1a1519877c62735a1/5ffd83f31950665b6b70bb2b_arrow.png" width="7" alt="" className="arrow" />
          </div>
          <nav className={`main-nav_dropdown_list w-dropdown-list w--nav-dropdown-list-open ${serviceActive ? 'w--open' : ''}`}>
            <a href="https://b2b.mtxc.eu/roaming-internet" className='main-nav_dropdown-link w-dropdown-link'>Roaming Internet</a>
            <a href="https://b2b.mtxc.eu/sim-and-esim-cards" className='main-nav_dropdown-link w-dropdown-link'>SIM and eSIM Cards</a>
            <a href="https://b2b.mtxc.eu/always-on" className='main-nav_dropdown-link w-dropdown-link'>Always On</a>
            <a href="https://b2b.mtxc.eu/coming-soon" className='main-nav_dropdown-link w-dropdown-link'>Roaming SMS</a>
            <a href="https://b2b.mtxc.eu/coming-soon" className='main-nav_dropdown-link w-dropdown-link'>Roaming Voice</a>
            <a href="https://b2b.mtxc.eu/coming-soon" className='main-nav_dropdown-link w-dropdown-link'>Mobile Numbers</a>
          </nav>
        </div>
        <a href="https://plte.mtxc.eu/" className="main-nav_nav-link w-inline-block">
          <div className="nav-link_link-text">Private LTE</div>
        </a>
        <div data-hover="true" data-delay="0" className="nav-menu_dropdown w-dropdown w--nav-dropdown-open">
          <div className={`main-nav_nav-link flex w-dropdown-toggle w--nav-dropdown-toggle-open ${mnoActive ? ' w--open' : ''}`} id="w-dropdown-toggle-2" aria-controls="w-dropdown-list-2" aria-haspopup="menu" aria-expanded="false" role="button" onClick={() => setMNOactive(!mnoActive)}>
            <div className="nav-link_link-text">MNO &amp; MVNO</div><img src="https://uploads-ssl.webflow.com/5ffd76b1a1519877c62735a1/5ffd83f31950665b6b70bb2b_arrow.png" width="7" alt="" className="arrow" />
          </div>
          <nav className={`main-nav_dropdown_list w-dropdown-list w--nav-dropdown-list-open ${mnoActive ? ' w--open' : ''}`}>
            <a href="https://b2b.mtxc.eu/carrier-solutions" className="main-nav_dropdown-link w-dropdown-link">Solutions home<strong><br /></strong></a>
            <a href="https://b2b.mtxc.eu/agreement-database-2-0" className="main-nav_dropdown-link w-dropdown-link">Agreement Database 2.0<strong><br /></strong></a>
            <a href="https://b2b.mtxc.eu/steering-control" className="main-nav_dropdown-link w-dropdown-link">Steering Control<strong><br /></strong></a>
            <a href="https://b2b.mtxc.eu/order-tables" aria-current="page" className="main-nav_dropdown-link w-dropdown-link w--current">Order Tables<strong><br /></strong></a>
          </nav>
        </div>
        <a data-w-id="28d276b3-2ac2-2461-97cc-c72003abe7e4" href="https://www.mtxc.eu/" className="main-nav_nav-link flex w-inline-block">
          <div className="nav-link_link-text">For individuals</div><img src={LinkExternal} width="7" alt="External page icon" className="navigation-link_icon medium" style={style3} />
        </a>
        <div className="visible-on-tablet-mobile">
          <a href="https://www.mtxc.eu/en/coverage.html" className="main-nav_nav-link w-inline-block">
            <div className="nav-link_link-text">Coverage</div>
          </a>
          <a href="https://faq.mtxc.eu/" className="main-nav_nav-link w-inline-block">
            <div className="nav-link_link-text">FAQ</div>
          </a>
          <div className="line-divider gray"></div>
          <div className="margin-20px">
            <div className="pading-horizontal _5">
              <div className="align-left">
                <a href="https://b2b.mtxc.eu/sign-up" className='button _200px full-on-mobile w-button'>Sign up</a>
                <div className="margin-20px">
                  <a href="https://b2b.mtxc.eu/login" className='button light-gray _200px full-on-mobile w-button'>Log in</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
      </div>}
    </div>
  </div>);
};

export default Header;
