import React from 'react';
import { Helmet } from "react-helmet";

const AppMeta: React.FC = () => {
  return (
    <Helmet>
      <meta name="google-play-app" content="app-id=com.mtxcb2b" />
      <meta name="apple-itunes-app" content="app-id=1502155805" />
      <meta property="al:ios:url" content="mtxconnect://b2b-portal-v2-dev.mtxc.eu" />
      <meta property="al:ios:app_store_id" content="1502155805" />
      <meta property="al:ios:app_name" content="MTX Connect for Business" />
      <meta property="al:android:url" content="mtxconnect://b2b-portal-v2-dev.mtxc.eu" />
      <meta property="al:android:package" content="com.mtxcb2b" />
      <meta property="al:android:app_name" content="MTX Connect for Business" />
    </Helmet>
  );
};

export default AppMeta;
