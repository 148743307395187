import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { toggleModal } from '../reducers/ModalsReducer';
import * as yup from "yup";

function getInputClass(form: any, key: string) {
  return `field w-input ${!!form.formState.errors[key] ? 'error-highlight' : ''}`;
}

const styleOpacity: React.CSSProperties = {
  opacity: 0,
  position: 'absolute',
  zIndex: -1
};

const contactSchema = yup.object().shape({
  contact_name: yup.string().required(),
  contact_email: yup.string().matches(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).when('contact_phone', {
      is: (contact_phone: any) => !contact_phone || contact_phone.length === 0,
      then: yup.string().matches(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).required(),
      otherwise: yup.string()
  }),
  contact_phone: yup.string().when('contact_email', {
      is: (contact_email: any) => !contact_email || contact_email.length === 0,
      then: yup.string().required(),
      otherwise: yup.string()
  }),
  preffed_contact: yup.string(),
  message: yup.string(),
  company: yup.string().required()
}, [ [ 'contact_email', 'contact_phone' ] ]);

const defaultContact = {
  contact_name: '',
  contact_email: '',
  contact_phone: '',
  preffed_contact: '',
  message: '',
  company: '',
}

type Props = {
  title: any;
};
const RequestModal: React.FC<Props> = ({ title }) => {
  const dispatch = useDispatch();
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'auto';
    }
  }, []);

  const contactForm = useForm({
    mode: 'onBlur',
    resolver: yupResolver(contactSchema),
    defaultValues: defaultContact
  });

  const prefContact = contactForm.watch('preffed_contact');

  const handleChange = useCallback((e, value: string) => {
    e.stopPropagation();
    e.preventDefault();
    contactForm.setValue('preffed_contact', value)
  }, []);

  const onSubmit = async(value: any) => {
    const body = {
      first_name: value.contact_name.split(' ')[0],
      last_name: value.contact_name.split(' ')[1] ? value.contact_name.split(' ')[1] : "n/a",
      topic: `Message from ${title}`,
      email: value.contact_email,
      queue_name: "b2b.mtxc.eu",
      message: value.message + '\n' + '\n' + '---' + '\n' + value.company
    }
    setLoading(true);
    await axios.post(`https://api.mtxc.eu/selfcare/v3/tickets`, body);
    setLoading(false);
    setSuccess(true);
    contactForm.reset();

    setTimeout(() => {
      setSuccess(false);
    }, 5000);
  };

  return (
    <div className="modal-parent" style={{display: 'block'}}>
      <div className="modal-centering">
        <div data-w-id="74b33293-4b3c-a90c-9099-02b5a50d1e82" className="modal-bg">
          <div className="w-container">
            <div className="form-modal padding-bottom-60px">
              <div className="modal-nav-wrapper justify-right">
                <a data-w-id="74b33293-4b3c-a90c-9099-02b5a50d1e86" className="close w-inline-block" onClick={() => dispatch(toggleModal('requestModal', null))}>
                  <div className="form-nav-text">Close</div>
                </a>
              </div>
              <div className="margin-30px _0-on-mobile">
                <div className="form-wrapper">
                  <div className="form-wrapper w-form">
                    <form onSubmit={contactForm.handleSubmit(onSubmit)}>
                      <div>
                        <div>
                          <h1 className="display-4">Request for information about {title}.</h1>
                        </div>
                      </div>
                      <div className="margin-40px _20px">
                        <div>
                          <h1 className="display-5">How should we contact you?</h1>
                        </div>
                      </div>

                      <div className="margin-20px">
                        <input type="text" className={getInputClass(contactForm, 'contact_name')} placeholder="Name" {...contactForm.register('contact_name')} />
                        <input type="text" className={getInputClass(contactForm, 'company')} placeholder="Company" {...contactForm.register('company')} />
                        <div className="w-layout-grid custom-input-group">
                          <input type="tel" className={`left ${getInputClass(contactForm, 'contact_email')}`} placeholder="Contact email" {...contactForm.register('contact_email')} />
                          <label className="custom-radio-field right hidden-on-mobile w-radio" onClick={(e) => handleChange(e, 'Email')}>
                            <div className={`w-form-formradioinput w-form-formradioinput--inputType-custom custom-radio right w-radio-input ${prefContact === 'Email' ? 'w--redirected-checked' : ''}`}></div>
                            <input type="radio" style={styleOpacity} />
                            <span className="custom-label small w-form-label">Preferred</span>
                          </label>
                        </div>
                        <div className="w-layout-grid custom-input-group">
                          <input type="tel" className={`left ${getInputClass(contactForm, 'contact_phone')}`} placeholder="Contact phone" {...contactForm.register('contact_phone', { setValueAs: v => { parseInt(v)}})} />
                          <label className="custom-radio-field right w-radio" onClick={(e) => handleChange(e, 'Phone')}>
                            <div className={`w-form-formradioinput w-form-formradioinput--inputType-custom custom-radio right w-radio-input ${prefContact === 'Phone' ? 'w--redirected-checked' : ''}`}></div>
                            <input type="radio" style={styleOpacity} />
                            <span className="custom-label small w-form-label">Preferred</span>
                          </label></div>
                      </div>

                      <div className="margin-30px">
                        <div className="flex-center">
                          <input type="submit" value={`${loading ? 'Please wait...' : 'Contact me'}`} data-wait="Please wait" className="button _300px w-button" />
                        </div>
                        <div className="flex-center">
                          <a data-w-id="2262a2a6-8834-8b82-b2d4-c7d149cec5f2" className="button empty _300px w-button" onClick={() => dispatch(toggleModal('requestModal', null))}>Cancel</a>
                        </div>
                      </div>
                    </form>
                    {success && <div className="success-message w-form-done" style={{display: 'block'}}>
                      <div>Thank you! Your submission has been received!</div>
                    </div>}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RequestModal;
