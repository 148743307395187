import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';
import * as yup from "yup";
import smoothscroll from 'smoothscroll-polyfill';
import MetaTags from './components/MetaTags';
import Crisp from './images/Crisp-Chat.svg';
import WhatpsUp from './images/WhatsApp-Logo.svg';
import Telegram from './images/Telegram-Logo.svg';
import Email from './images/Email.svg';
import Phone from './images/Phone.svg';
import AppMeta from './components/AppMeta';
import { AppState } from './store';
import RequestModal from './components/RequetModal';
import './styles/normalize.scss';
import './styles/webflow.scss';
import './styles/mtxc.webflow.scss';
import './styles/Onboarding.scss';
import Header from './components/Header';
import Footer from './components/Footer';

function getInputClass(form: any, key: string) {
  return `field w-input ${!!form.formState.errors[key] ? 'error-highlight' : ''}`;
}
const styleOpacity: React.CSSProperties = {
  opacity: 0,
  position: 'absolute',
  zIndex: -1
}

smoothscroll.polyfill();

function App() {
  const [showScrollTop, setshowScrollTop] = useState(false);
  const requestModal = useSelector((state: AppState) => state.modals.requestModal);
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    $crisp.push(["set", "session:segments", [["b2b_portal"]]]);
    $crisp.push(["do", "chat:hide"]);
    $crisp.push(["on", "chat:closed", () => {
      $crisp.push(["do", "chat:hide"]);
    }]);
    window.scrollTo(0, 0);
    document.body.classList.add("onboarding");
    document.addEventListener('scroll', () => setshowScrollTop(window.pageYOffset >= 150));

    return () => {
      document.removeEventListener('scroll', () => setshowScrollTop(false));
      document.body.classList.remove("onboarding");
    }
  }, []);

  const scrollTop = useCallback(() => {
    const el = document.getElementById('Top');
    if (el) {
      let top = el.getBoundingClientRect().top + window.pageYOffset
      window.scrollTo({top, behavior: 'smooth'});
    }
  }, []);

  const openCrisp = useCallback(() => {
    $crisp.push(["do", "chat:show"]);
    $crisp.push(['do', 'chat:open']);
  }, []);

  const contactSchema = yup.object().shape({
    contact_name: yup.string().required(),
    contact_email: yup.string().matches(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).required(),
    contact_phone: yup.string().required(),
    preffed_contact: yup.string(),
    message: yup.string(),
  }, [ [ 'contact_email', 'contact_phone' ] ]);

  const defaultContact = {
    contact_name: '',
    contact_email: '',
    contact_phone: '',
    preffed_contact: 'Email',
    message: ''
  }

  const contactForm = useForm({
    mode: 'onBlur',
    resolver: yupResolver(contactSchema),
    defaultValues: defaultContact
  });
  const prefContact = contactForm.watch('preffed_contact');

  const handleChange = useCallback((e, value: string) => {
    e.stopPropagation();
    e.preventDefault();
    contactForm.setValue('preffed_contact', value)
  }, []);

  const onSubmit = async(value: any) => {
    const body = {
      first_name: value.contact_name.split(' ')[0],
      last_name: value.contact_name.split(' ')[1] ? value.contact_name.split(' ')[1] : "n/a",
      topic: 'Message from MTX Connecy Universal Contacts Page',
      email: value.contact_email,
      queue_name: "b2b.mtxc.eu",
      message: value.message + '\n' + '\n' + '---' + '\n' + `+${value.contact_phone}`
    }
    setLoading(true);
    await axios.post(`https://api.mtxc.eu/selfcare/v3/tickets`, body);
    setLoading(false);
    setSuccess(true);
    contactForm.reset();

    setTimeout(() => {
      setSuccess(false);
    }, 5000);
  };

  return (<>
    <AppMeta />
    <MetaTags title="Contact Us — MTX Connect" />
    {requestModal.active && <RequestModal title={requestModal.data?.from} />}
    <Header />
    <section className="section wf-section">
      <div className="container flex w-container">
        <div className="margin-40px">
          <div className="form-wrapper w-form">
            <div className="messenger-wrapper">
              <h1 className="display-3">Welcome to MTX Connect, a Luxembourg-based mobile operator</h1>
              <div className="margin-20px">
                <p className="paragraph">If you have any questions or need assistance from our technical or commercial teams, please contact us using one of the methods listed below.</p>
              </div>
              <div className="margin-20px">
                <a href="https://wa.me/441866960072" className="messenger-button green w-inline-block"><img src={WhatpsUp} loading="lazy" alt="WhatsApp" className="messanger-button---icon" />
                  <div>Chat on WhatsApp</div>
                </a>
                <a href="https://t.me/MTXConnectSupport_bot" className="messenger-button blue w-inline-block"><img src={Telegram} loading="lazy" alt="Telegram" className="messanger-button---icon" />
                  <div>Chat on Telegram</div>
                </a>
                <a href="mailto:sales@mtxc.eu?subject=Message%20from%20MTX%20Connect%20universal%20contact%20page" className="messenger-button w-inline-block"><img src={Email} loading="lazy" alt="Email" className="messanger-button---icon medium" />
                  <div>Write us an email</div>
                </a>
                <a onClick={openCrisp} className="messenger-button w-inline-block"><img src={Crisp} loading="lazy" alt="Support Chat" className="messanger-button---icon" />
                  <div>Chat with our Support</div>
                </a>
                <a href="tel:+35220210072" className="messenger-button w-inline-block"><img src={Phone} loading="lazy" alt="Phone" className="messanger-button---icon" />
                  <div>Call us at +35220210072</div>
                </a>
              </div>
            </div>
            <div className="line-divider gray margin-vertical-15px"></div>
            <div className="margin-30px">
              <h2 className="display-4">Request a call from us</h2>
              <p className="paragraph">Please tell us how we should contact you</p>
              <form onSubmit={contactForm.handleSubmit(onSubmit)}>
                <div className="margin-30px">
                  <input type="text" className={getInputClass(contactForm, 'contact_name')} placeholder="Name" {...contactForm.register('contact_name')} />
                  <div className="w-layout-grid custom-input-group">
                    <input type="text" className={`left ${getInputClass(contactForm, 'contact_email')}`} placeholder="Contact email" {...contactForm.register('contact_email')} />
                    <label className="custom-radio-field right hidden-on-mobile w-radio" onClick={(e) => handleChange(e, 'Email')}>
                      <div className={`w-form-formradioinput w-form-formradioinput--inputType-custom custom-radio right w-radio-input ${prefContact === 'Email' ? 'w--redirected-checked' : ''}`}></div>
                      <input type="radio" style={styleOpacity} />
                      <span className="custom-label small w-form-label">Preferred</span>
                    </label>
                  </div>
                  <div className="w-layout-grid custom-input-group">
                    <input className={`left ${getInputClass(contactForm, 'contact_phone')}`} placeholder="Contact phone" {...contactForm.register('contact_phone')} type="number" />
                    <label className="custom-radio-field right w-radio" onClick={(e) => handleChange(e, 'Phone')}>
                      <div className={`w-form-formradioinput w-form-formradioinput--inputType-custom custom-radio right w-radio-input ${prefContact === 'Phone' ? 'w--redirected-checked' : ''}`}></div>
                      <input type="radio" style={styleOpacity} />
                      <span className="custom-label small w-form-label">Preferred</span>
                    </label></div>
                    <textarea {...contactForm.register('message')} placeholder="Optional comment" className="textarea w-input" />
                </div>
                <div className="margin-30px">
                  <div className="flex-center"><input type="submit" value={`${loading ? 'Please wait...' : 'Contact me'}`} data-wait="Please wait" className="button full w-button" /></div>
                </div>
              </form>
            </div>
            <br />
            {success && <div className="success-message w-form-done" style={{display: 'block'}}>
              <div>Thank you! Your submission has been received!</div>
            </div>}
          </div>
        </div>
      </div>
    </section>
    <a onClick={scrollTop} className={`go-top w-inline-block ${showScrollTop ? 'active' : ''}`}>
      <img src="https://uploads-ssl.webflow.com/5ffd76b1a1519877c62735a1/5ffda90db6bf3fea11a6c527_icons8-up-144.png" width="20" alt="" className="top-arrow" />
    </a>
    <Footer />
  </>);
}

export default App;
