
export type ModalsState = {
  [key: string]: {
    active: boolean;
    data: any
  }
}

export type ToggleModal = (name: string, data?: any) => void;

export function toggleModal(name: string, data: any = null) {
  return {
    type: 'TOGGLE_MODAL',
    name,
    data
  };
}

const initialState: ModalsState = {
  requestModal: {
    active: false,
    data: null
  }
};

type Action = {
  type: string;
  name: string;
  data: any;
}
export default (state = initialState, action: Action) => {
  switch (action.type) {
    case 'TOGGLE_MODAL':
      return {
        ...state,
        [action.name]: {
          active: !state[action.name]?.active,
          data: action.data
        }
      }
    default:
      return state;
  }
};
