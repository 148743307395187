import React from 'react';
import { Helmet } from "react-helmet";

type Props = { title?: string } ;
const MetaTags: React.FC<Props> = ({ title }) => {
  return (
    <Helmet>
      <title>{title ? `${title} - ` : ''}MTX Connect Business</title>
      <meta content={`${title ? `${title} - ` : ''}MTX Connect Business`} property="og:title" />
      <meta content={`${title ? `${title} - ` : ''}MTX Connect Business`} property="twitter:title" />
      <meta content="width=device-width, initial-scale=1" name="viewport" />
    </Helmet>
  );
};

export default MetaTags;
