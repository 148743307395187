import React from 'react';
import { useDispatch } from 'react-redux';
import { toggleModal } from '../reducers/ModalsReducer';
import made_in_luxembourg from '../images/made_in_luxembourg.svg';
import google_play from '../images/google_play.svg';
import app_store from '../images/app_store.svg';
import facebookWhite from '../images/facebook-white.svg';
import instagramWhite from '../images/instagram-white.svg';
import twitterWhite from '../images/twitter-white.svg';

const styleHidden: any = {
  opacity:0,
  position:'absolute',
  zIndex: -1
}

type Props = {};
const Footer: React.FC<Props> = ({}) => {
  const dispatch = useDispatch();

  return (
    <>
    {/* <section className="section blue _80px wf-section">
      <div className="container">
        <div className="cta-grid">
          <div className="center-mobile">
            <h3 className="display-2 apricot">Get connected. Get working. Get MTX Connect Business.</h3>
          </div>
          <div id="w-node-_747d24e4-141f-4a7c-c0d0-ae92f97a936d-f97a9367" className="align-center">
            <a className="button whiter _200px w-button" onClick={() => dispatch(toggleModal('requestModal', { from: 'contact sales', custom: true }))}>Contact sales</a>
            <div className="margin-10px">
              <p className="paragraph-small white">Or call <a href="tel:+35220210072" className="white">+352 20 210072</a></p>
            </div>
          </div>
        </div>
      </div>
    </section> */}
      <div className="footer-1">
        <div className="container">
          <div className="footer-grid">
          <div id="w-node-_7efb4836-59c1-4a54-2678-7f50065e3f48-065e3f45">
            <img src={made_in_luxembourg} loading="lazy" width="95" alt="" className="footer-logo" />
            <div className="margin-30px">
              <p className="big-paragraph white">MTX Connect Business. Corporate mobile connectivity services.</p>
              <p className="footer-text _90">MTX Connect s.a.r.l. licensed by Institut Luxembourgeois de Régulation (ILR) in Luxembourg with TADIG: MTX01 MCC/MNC: 270 02 and maintains GSMA Rapporteur membership since 2013.<br /></p>
            </div>
            <div className="margin-30px hidden">
              <div className="_90">
                <p className="footer-text">Subscribe to our Newsletter<br /></p>
                <div className="footer-subscribe w-form">
                  <form id="wf-form-Subscribe" name="wf-form-Subscribe" data-name="Subscribe" className="subscribe-form for-footer">
                    <input type="email" className="subscribe-field no-icon w-node-_7efb4836-59c1-4a54-2678-7f50065e3f8e-065e3f45 w-input" placeholder="Enter your email" id="Email" />
                    <input type="submit" value="Subscribe" data-wait="Please wait..." className="button subscribe button-mobile w-button" />
                    <div className="margin-15px">
                      <label className="w-checkbox checkbox-field">
                        <div className="w-checkbox-input w-checkbox-input--inputType-custom checkbox"></div>
                        <input type="checkbox" id="Checked" name="checkbox" style={styleHidden} />
                        <span className="checkbox-label white w-form-label">I agree to the
                          <a href="https://b2b.mtxc.eu/legal">Terms &amp; Conditions</a>
                        </span>
                      </label></div>
                  </form>
                </div>
              </div>
            </div>
          </div>
            <div id="w-node-_7efb4836-59c1-4a54-2678-7f50065e3f50-065e3f45" className="footer-flex">
              <div className="nav-heading">Legal</div>
              <a href="https://www.mtxc.eu/en/description-of-services.html" target="_blank" className="footer-link w-inline-block">
                <div className="nav-text remove-weight">Description of Services</div>
              </a>
              <a href="https://www.mtxc.eu/en/privacy-policy.html" target="_blank" className="footer-link w-inline-block">
                <div className="nav-text remove-weight">Privacy Policy</div>
              </a>
              <a href="https://corp.mtxc.eu/en/" target="_blank" className="footer-link w-inline-block">
                <div className="nav-text remove-weight">Company information</div>
              </a>
              <a href="" className='footer-link w-inline-block'>
                <div className="nav-text remove-weight">General Terms and Conditions</div>
              </a>
              <div className="margin-30px">
                <div className="footer-flex">
                  <div className="footer-flex">
                    <div className="nav-heading">Helpful links</div>
                    <a href="https://faq.mtxc.eu/en-us/" className="footer-link w-inline-block">
                      <div className="nav-text remove-weight">Help center</div>
                    </a>
                    <a href="https://b2b.mtxc.eu/login" className="footer-link w-inline-block">
                      <div className="nav-text remove-weight">Sign in</div>
                    </a>
                    <a href="https://b2b.mtxc.eu/sign-up" className="footer-link w-inline-block">
                      <div className="nav-text remove-weight">Sign up</div>
                    </a>
                    <a href="https://www.mtxc.eu/" className="footer-link w-inline-block">
                      <div className="nav-text remove-weight">MTX Connect for Travellers</div>
                    </a>
                  </div>
                </div>
              </div>
              <div className="margin-30px">
                <div className="footer-flex">
                  <div className="nav-heading">Contact</div>
                  <a href="https://g.page/mtx-connect-pontpierre?share" target="_blank" className="footer-link w-inline-block">
                    <div className="nav-text remove-weight">MTX Connect S.a r.l. <br/>37 Grand Rue <br/>L–4393 Pontpierre<br/>Luxembourg</div>
                  </a>
                  <a href="tel:+35220210072" className="footer-link w-inline-block">
                    <div className="nav-text remove-weight"><span className="inline-icon"></span>+352 2021 0072</div>
                  </a>
                  <a href="mailto:corp@mtxc.eu?subject=Message%20from%20MTXC%20B2B%20portal" className="footer-link w-inline-block">
                    <div className="nav-text remove-weight"><span className="inline-icon"></span>corp@mtxc.eu</div>
                  </a>
                </div>
              </div>
            </div>
            <div id="w-node-_7efb4836-59c1-4a54-2678-7f50065e3f63-065e3f45" className="footer-flex hidden">
              <div className="nav-heading">Mobile apps</div>
              <div className="footer-flex">
                <a href="https://play.google.com/store/apps/details?id=com.mtxcb2b" target="_blank" className="footer-link stores w-inline-block">
                  <img src={google_play} loading="lazy" alt="" className="store-badge" />
                </a>
                <a href="https://apps.apple.com/ro/app/mtx-connect-for-business/id1502155805" target="_blank" className="footer-link stores w-inline-block">
                  <img src={app_store} loading="lazy" alt="" className="store-badge" />
                </a>
              </div>
            </div>
            <div id="w-node-_7efb4836-59c1-4a54-2678-7f50065e3f7a-065e3f45" className="footer-flex">
              <div className="social-wrapper vertical">
                <a href="http://facebook.com/mtxconnect/" target="_blank" className="social-icon _2 w-inline-block">
                  <img src={facebookWhite} loading="lazy" width="18" alt="" />
                </a>
                <a href="https://instagram.com/mtxconnect/" target="_blank" className="social-icon _2 w-inline-block">
                  <img src={instagramWhite} loading="lazy" width="18" alt="" />
                </a>
                <a href="http://twitter.com/mtxconnect/" target="_blank" className="social-icon _2 w-inline-block">
                  <img src={twitterWhite} loading="lazy" width="18" alt="" />
                </a>
              </div>
            </div>
          </div>
          <div className="footer-line"></div>
          <div>
            <div className="copyright-flex">
              <p className="copyright">© {new Date().getFullYear()} MTX Connect</p>
              <p className="copyright">RCS Luxembourg B177706 · TVA LU26319825 · Business licence no. 10039784/5</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
