import { combineReducers, createStore, Store, applyMiddleware, compose, Reducer, CombinedState } from 'redux';
import thunk from 'redux-thunk';
import ModalsReducer, { ModalsState } from './reducers/ModalsReducer';


export interface AppState {
  modals: ModalsState,
}

const appReducer = combineReducers<AppState>({
  modals: ModalsReducer,
});

const rootReducer = (state: AppState |  undefined, action: any) => {
  return appReducer(state, action)
}

const composeEnhancers =
  typeof window === 'object' &&
  (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?
  (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
  : compose;

function configureStore(): Store<AppState> {
  const store = createStore(
    rootReducer as Reducer<CombinedState<AppState>>,
    composeEnhancers(
      applyMiddleware(thunk)
    )
  );
  return store;
}
const store = configureStore();
export default store;
